<template>
  <v-container>
    <v-app-bar
      absolute
      app
      color="#111213"
      flat
      height="75"
      style="width: auto"
    >
      <v-btn
        fab
        small
        @click="
          $vuetify.breakpoint.smAndDown
            ? setDrawer(!drawer)
            : $emit('input', !value)
        "
      >
        <v-icon v-if="value">mdi-view-quilt</v-icon>
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-spacer />

      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn fab small style="margin-top: -5px">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>

      <div class="mx-3" />
      <v-btn
        class="ma-2"
        tile
        outlined
        :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
        @click="salir()"
      >
        <v-icon>mdi-logout</v-icon>
        {{ Username }}
      </v-btn>
    </v-app-bar>
  </v-container>
</template>
<script>

import { mapState, mapMutations } from 'vuex'

export default {
    name: 'DashboardCoreAppBar',

    components: {},

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        Username: ''
    }),

    computed: {
        ...mapState(['barColor', 'barImage', 'drawer'])
    },

    methods: {
        ...mapMutations({
            setDrawer: 'SET_DRAWER'
        }),
        salir () {
            this.$store.dispatch('setComercial', '')
            this.$store.dispatch('setLoggedOut')
            this.$router.push('/')
        },
        UserLogeado () {
            this.Username = this.$store.getters.getComercial.email
        }
    },
    created () {
        this.UserLogeado()
    }
}
</script>
